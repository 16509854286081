import { useDataStore } from '@/js/stores/DataStore.js';

const recalculateDepartment = (departmentId) => {
    const baseKey = `current_event.cost_departments.${departmentId}`;
    const department = useDataStore().get(baseKey);
    if (!department) {
        // eslint-disable-next-line no-console
        console.error('Attempted to recalculate a department that does not exist: ', departmentId);
        return;
    }
    const keys = [
        'total_actual',
        'total_budget_entries.draft',
        'total_budget_entries.approvals',
        'total_budget_entries.approved',
        'total_budget_entries.rejected',
        'total_budget_entries.total',
        'total_budget_estimates.draft',
        'total_budget_estimates.approvals',
        'total_budget_estimates.approved',
        'total_budget_estimates.rejected',
        'total_budget_estimates.total',
        'total_budgeted',
        'total_credit',
        'total_fixed',
        'total_invoiced',
        'total_invoiced_gross',
        'total_markup',
        'total_paid',
    ];
    keys.forEach((key) => {
        useDataStore().set(`${baseKey}.${key}`, reduceFromSubcategories(department.categories, key));
    });
};

const recalculateRevenueStream = (revenueStreamId) => {
    const baseKey = `current_event.revenue_streams.${revenueStreamId}`;
    const revenueStream = useDataStore().get(baseKey);
    if (!revenueStream) {
        // eslint-disable-next-line no-console
        console.error('Attempted to recalculate a revenue stream that does not exist: ', revenueStreamId);
        return;
    }
    const keys = [
        'total_actual',
        'total_actual_costs',
        'total_budget_entries.draft',
        'total_budget_entries.approvals',
        'total_budget_entries.approved',
        'total_budget_entries.rejected',
        'total_budget_entries.total',
        'total_budget_estimates.draft',
        'total_budget_estimates.approvals',
        'total_budget_estimates.approved',
        'total_budget_estimates.rejected',
        'total_budget_estimates.total',
        'total_budgeted',
        'total_credit',
        'total_fixed',
        'total_fixed_costs',
        'total_invoiced',
        'total_invoiced_gross',
        'total_markup',
        'total_paid',
        'total_pending',
    ];
    keys.forEach((key) => {
        useDataStore().set(`${baseKey}.${key}`, reduceFromSubcategories(revenueStream.categories, key));
    });
};

const reduceFromSubcategories = (subcategories, key, baseKey) => {
    return Object.values(subcategories).reduce((accumulator, subcategory) => {
        const keys = key.split('.');
        let value = useDataStore().get(`current_event.categories.${subcategory.id}`);
        if (!value) {
            return accumulator + 0;
        }
        keys.forEach((k) => {
            value = value[k];
        });
        useDataStore().set(`${baseKey}.categories.${subcategory.id}.${key}`, value);
        return accumulator + value;
    }, 0);
};

export { recalculateDepartment, recalculateRevenueStream };
