<template>
    <DataTable
        ref="table"
        :key="store.get('current_scenario.id')"
        v-model="selectedRows"
        centered
        searchable
        :endpoint
        :filters="isApprovalsArea ? null : filterable ? filters : null"
        :checkable
        :columns="[
            {
                title: 'Status',
                field: 'status',
            },
            {
                title: 'Title',
                field: 'name',
            },
            {
                title: 'Subcategory',
                field: 'category_name',
                hidden: hideCategory,
            },
            {
                title: 'Supplier',
                field: 'suppliers.name',
                sortable: true,
            },
            {
                title: 'Markup',
                field: 'markup',
                hidden: !hasMarkups || hiddenColumns.includes('markup'),
            },
            {
                title: 'Fixed',
                field: 'budget_entries.fixed_cost',
                width: '140',
                sortable: true,
                hidden: hiddenColumns.includes('fixed'),
            },
            {
                title: 'Budgeted',
                field: 'budget_entries.budgeted_cost',
                width: '140',
                sortable: true,
                hidden: hiddenColumns.includes('budgeted'),
            },
            {
                title: 'Committed',
                field: 'total_committed',
                width: '140',
                sortable: true,
                hidden: hiddenColumns.includes('actual'),
            },
            {
                title: 'Variance',
                field: 'variance',
                hidden: hiddenColumns.includes('variance'),
            },
            {
                field: 'comments',
                width: '40',
                hidden: hiddenColumns.includes('comments'),
            },
            {
                field: 'actions',
            },
        ]"
        :row-class="
            (row) => {
                if (row.markup_source) {
                    return 'tw-border-l-4 tw-border-sky-500 tw-bg-sky-50';
                }
                if (row.markup) {
                    return 'tw-border-l-4 tw-border-amber-500 tw-bg-amber-50';
                }
            }
        "
    >
        <template #filters>
            <FormField class="tw-mt-2 tw-border tw-border-gray-200 tw-py-1 tw-px-2 tw-rounded-[4px]" label="Status">
                <Checkbox
                    v-for="option of ['Draft', 'Awaiting Approval', 'Awaiting Reapproval', 'Approved', 'Rejected']"
                    :id="`filter-status-${option.toLowerCase()}`"
                    :key="option"
                    :model-value="option !== 'Rejected'"
                    @update:model-value="(v) => onFilterUpdate(v, option)"
                >
                    {{ option }}
                </Checkbox>
            </FormField>
        </template>
        <template v-if="selectedRows.length" #before>
            <div class="tw-flex tw-border-b tw-bg-gray-100 tw-p-3">
                You have selected {{ selectedRows.length }} {{ selectedRows.length === 1 ? 'entry' : 'entries' }}
                <ApprovalControls
                    class="tw-ml-auto"
                    :bulk-controls="true"
                    :approvables="selectedRows"
                    :hide-request="isApprovalsArea"
                    :hide-actions="userCannotApproveSelected"
                    :locked="eventIsClosed"
                    @reload="reloadData(true)"
                />
            </div>
        </template>
        <template #column(status)="{ row }">
            <Tag :status="row.status" />
        </template>
        <template #column(name)="{ row }">
            {{ row.name }}
        </template>
        <template v-if="!hideCategory" #column(category_name)="{ row }">
            <a href="#" :title="row.category.name" @click.prevent="openCategory(row.category)">
                <i
                    v-if="row.category.xero_account_id"
                    class="mdi mdi-swap-horizontal-circle text-xero"
                    style="font-size: 1rem"
                    title="Connected to Xero"
                ></i>
                {{ row.category.name }}
            </a>
        </template>
        <template #column(suppliers.name)="{ row }">
            <a v-if="row.supplier" href="#" :title="row.supplier.name" @click.prevent="openSupplier(row.supplier)">
                <i
                    v-if="row.supplier.xero_contact_id"
                    class="mdi mdi-swap-horizontal-circle text-xero"
                    style="font-size: 1rem"
                    title="Connected to Xero"
                ></i>
                {{ row.supplier.name }}
            </a>
            <span v-else class="tw-text-gray-400">Unassigned</span>
        </template>
        <template #column(budget_entries.fixed_cost)="{ row }">
            {{ formatCurrency(row.fixed_cost, 2, eventCurrency) }}
        </template>
        <template #column(budget_entries.budgeted_cost)="{ row }">
            <Textbox
                v-if="canUpdate"
                v-model="row.budgeted_cost"
                type="currency"
                step=".01"
                :disabled="!checkable"
                toggle
                compact
                @update:model-value="updateBudgeted(row)"
            >
                <template #before>
                    <span
                        class="tw-flex tw-items-center tw-justify-center tw-text-gray-500 tw-w-[24px] tw-border-y tw-border-s tw-border-red tw-pb-[1px] tw-rounded-l-[2px]"
                    >
                        {{ formatCurrency(0, 0, eventCurrency).replace('0', '') }}
                    </span>
                </template>
            </Textbox>
            <span v-else>{{ formatCurrency(row.budgeted_cost, 2, eventCurrency) }}</span>
        </template>
        <template #column(markup)="{ row }">
            <Button
                v-if="!row.markup && Auth.can('create budget entries')"
                type="is-link"
                class="!tw-p-0"
                @click="createMarkup(row)"
            >
                Add
            </Button>
            <span v-else-if="!row.markup" class="tw-text-gray-400">&mdash;</span>
            <Button
                v-else-if="!row.is_editing_markup"
                type="is-link"
                class="!tw-px-0"
                @click="row.is_editing_markup = true"
            >
                {{ formatCurrency(row.markup.budgeted_cost - row.budgeted_cost, 2, eventCurrency) }}
            </Button>
            <PercentageValue
                v-else-if="row.is_editing_markup"
                :model-value="row.markup.budgeted_cost - row.budgeted_cost"
                :base-value="row.budgeted_cost"
                :loading="isSavingMarkup"
                currency
                @keypress.native.enter="saveMarkup(row)"
                @update:model-value="(val) => (row.new_markup_val = val)"
            />
        </template>
        <template #column(total_committed)="{ row }">
            {{ formatCurrency(row.total_committed, 2, eventCurrency) }}
        </template>
        <template #column(variance)="{ row }">
            {{ formatCurrency(row.total_committed - parseFloat(row.budgeted_cost), 2, eventCurrency) }}
        </template>
        <template #column(comments)="{ row }">
            <Tooltip label="External Comments">
                <Tag
                    v-if="row.comments && row.comments.length"
                    class="tw-flex tw-items-center tw-gap-[2px] !tw-py-0 !tw-px-1.5"
                >
                    <i class="mdi mdi-comment-text-outline"></i>
                    <span class="tw-mb-[1px] tw-text-sm">{{ row.comments.length }}</span>
                </Tag>
            </Tooltip>
        </template>
        <template #column(actions)="{ row }">
            <div class="tw-flex tw-gap-1 tw-justify-end">
                <Tooltip
                    v-if="canUpdate && !row.parent_id && !row.is_locked && getEntryType(row) === 'cost'"
                    position="left"
                    label="Recharge"
                >
                    <Button @click.stop="rechargeEntry(row)"><i class="mdi mdi-cash-multiple"></i></Button>
                </Tooltip>
                <Tooltip
                    v-if="Auth.can('view budget entries') && row.markup"
                    position="left"
                    :label="`View Markup (${row.markup.status})`"
                >
                    <Button
                        :type="row.markup.status !== 'Approved' ? 'is-warning' : null"
                        @click="openEntry(row.markup)"
                    >
                        <i class="mdi mdi-tag-arrow-up"></i>
                    </Button>
                </Tooltip>
                <Tooltip v-if="Auth.can('view budget entries')" position="left" label="View">
                    <Button @click.stop="openEntry(row)"><i class="mdi mdi-eye"></i></Button>
                </Tooltip>
            </div>
        </template>
        <template #expanded-row="{ row }">
            <div class="tw-bg-gray-50 tw-pt-1 tw-px-3 tw-font-avenir tw-text-slate-600">External Comments</div>
            <DataTable
                :header="false"
                :columns="[
                    {
                        title: 'User',
                        field: 'user',
                    },
                    {
                        title: 'Comment',
                        field: 'text',
                    },
                ]"
                :data="row.comments"
            >
                <template #column(user)="{ row }">
                    <User :user="row.user" size="xsmall" :hide-name="false">
                        <template #default="{ user }">
                            <div class="tw-flex tw-flex-col tw-leading-4">
                                <span>{{ user.name }}</span>
                                <span class="tw-text-xs tw-text-gray-400">
                                    {{ toLocal(row.created_at).toLocaleString(DateTime.DATETIME_MED) }}
                                </span>
                            </div>
                        </template>
                    </User>
                </template>
            </DataTable>
        </template>
        <template v-if="Auth.can('create budget entries') && checkable" #append>
            <tr
                v-if="createable"
                class="tw-opacity-[75%] hover:tw-opacity-[100%] tw-bg-white focus-within:tw-opacity-[100%]"
            >
                <td v-if="!isCreating" :colspan="(hideCategory ? 9 : 10) + (hasMarkups ? 1 : 0)" class="!tw-p-0">
                    <Button
                        class="tw-w-full tw-border-0 tw-flex tw-gap-2 tw-justify-center tw-pt-[11px] tw-pb-[12px] tw-bg-gray-50"
                        @click="isCreating = true"
                    >
                        <i class="mdi mdi-receipt-text-plus-outline"></i>
                        Create a new budget entry
                    </Button>
                </td>
                <template v-else>
                    <td v-if="$refs.table.hasResults"></td>
                    <td>
                        <Tag>Draft</Tag>
                    </td>
                    <td>
                        <Textbox
                            ref="titleTextbox"
                            v-model="newEntryData.name"
                            placeholder="Create a new budget entry..."
                            expanded
                            @keydown.esc.native="isCreating = false"
                        ></Textbox>
                    </td>
                    <td v-if="!hideCategory">
                        <Selector
                            v-if="!isCategory"
                            v-model="newEntryData.category_id"
                            placeholder="Category"
                            input-class="input is-small"
                            :options="availableCategories"
                            @keydown.tab.native="setSupplierSelection"
                        />
                        <span v-else>{{ object.name }}</span>
                    </td>
                    <td>
                        <Selector
                            ref="supplierSelector"
                            v-model="newEntryData.supplier_id"
                            placeholder="Contact"
                            input-class="input is-small"
                            :options="availableSuppliers"
                            @keydown.tab.native="setFixedSelection"
                        >
                            <template #customOptions="{ searchValue, hasExactMatch }">
                                <a
                                    v-if="Auth.can('manage suppliers') && !hasExactMatch"
                                    href="#"
                                    @mousedown="createSupplier(searchValue)"
                                >
                                    <i class="mdi mdi-plus"></i>
                                    Create {{ searchValue ? searchValue : ' a new contact' }}
                                </a>
                            </template>
                        </Selector>
                    </td>
                    <td width="150">
                        <Textbox ref="fixedInput" v-model="newEntryData.fixed_cost" type="currency" step=".01">
                            <template #before>
                                <span
                                    class="tw-flex tw-items-center tw-justify-center tw-text-gray-500 tw-w-[24px] tw-border-y tw-border-s tw-border-red tw-pb-[1px] tw-rounded-l-[2px]"
                                >
                                    {{ formatCurrency(0, 0, eventCurrency).replace('0', '') }}
                                </span>
                            </template>
                        </Textbox>
                    </td>
                    <td width="150">
                        <Textbox
                            v-model="newEntryData.budgeted_cost"
                            type="currency"
                            step=".01"
                            @keydown.enter.native="createEntry()"
                        >
                            <template #before>
                                <span
                                    class="tw-flex tw-items-center tw-justify-center tw-text-gray-500 tw-w-[24px] tw-border-y tw-border-s tw-border-red tw-pb-[1px] tw-rounded-l-[2px]"
                                >
                                    {{ formatCurrency(0, 0, eventCurrency).replace('0', '') }}
                                </span>
                            </template>
                        </Textbox>
                    </td>
                    <td class="!tw-text-right" :colspan="hasMarkups ? 4 : 3">
                        <Button :disabled="!isValid" @click="createEntry()">Create</Button>
                    </td>
                </template>
            </tr>
        </template>
        <template #after>
            <div class="tw-border tw-rounded tw-mx-3 tw-mb-3 tw-p-2">
                <FormField label="Legend">
                    <div class="tw-flex tw-flex-wrap tw-gap-4">
                        <div class="tw-flex tw-items-center tw-gap-2">
                            <span class="tw-w-4 tw-h-4 tw-bg-amber-100 tw-border-l-2 tw-border-amber-500"></span>
                            Markup Source
                        </div>
                        <div class="tw-flex tw-items-center tw-gap-2">
                            <span class="tw-w-4 tw-h-4 tw-bg-sky-100 tw-border-l-2 tw-border-sky-500"></span>
                            Markup Target
                        </div>
                    </div>
                </FormField>
            </div>
            <ModalCreateSupplier
                v-if="Auth.can('manage suppliers')"
                ref="createSupplierModal"
                object-label="contact"
                @created="supplierCreated"
            />
            <BudgetMarkup ref="budgetmarkupmodal" @created="reloadData(true)" />
        </template>
    </DataTable>
</template>
<script>
import FormField from '../../widgets/FormField.vue';
import Checkbox from '../../controls/Checkbox.vue';
import DataTable from '../DataTable.vue';
import ApprovalControls from '../../widgets/ApprovalControls.vue';
import Textbox from '../../controls/Textbox.vue';
import Selector from '../../controls/Selector.vue';
import Button from '../../controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import { useSettingsStore } from '@/js/stores/SettingsStore.js';
import { formatCurrency, setRoute, toast, Auth, toLocal } from '@/js/utils.js';
import ModalCreateSupplier from '@/js/components/modals/Suppliers/CreateSupplier.vue';
import Tooltip from '@/js/components/Tooltip.vue';
import Tag from '@/js/components/Tag.vue';
import PercentageValue from '@/js/components/widgets/PercentageValue.vue';
import User from '@/js/components/widgets/User.vue';
import BudgetMarkup from '@/js/components/modals/Budgeting/BudgetMarkup.vue';
import { DateTime } from 'luxon';

export default {
    components: {
        FormField,
        Checkbox,
        DataTable,
        ApprovalControls,
        Textbox,
        Selector,
        Button,
        Tag,
        ModalCreateSupplier,
        Tooltip,
        PercentageValue,
        User,
        BudgetMarkup,
    },
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        filterable: {
            type: Boolean,
            default: false,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        object: {
            type: Object,
            required: false,
        },
        createable: {
            type: Boolean,
            default: false,
        },
        hideCategory: {
            type: Boolean,
            default: false,
        },
        isApprovalsArea: {
            type: Boolean,
            default: false,
        },
        hiddenColumns: {
            type: Array,
            default: () => [],
        },
        hasMarkups: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        const store = useDataStore();
        const localSettings = useSettingsStore();
        return {
            isCreating: false,
            isSavingMarkup: false,
            newEntryData: {
                name: '',
                category_id: null,
                supplier_id: Object.values(store.get('account.suppliers')).find((supplier) => {
                    return supplier.is_system && supplier.name === 'TBC';
                })?.id,
                user_id: store.get('current_user.id'),
                fixed_cost: 0,
                budgeted_cost: 0,
            },
            selectedRows: [],
            availableSuppliers: Object.values(store.get('account.suppliers')).filter((supplier) => {
                return !supplier.is_archived;
            }),
            store,
            localSettings,
            formatCurrency,
            filters: {
                'budget_entries.status': ['Draft', 'Awaiting Approval', 'Awaiting Reapproval', 'Approved'],
            },
            DateTime,
        };
    },
    computed: {
        eventIsClosed() {
            return this.store.get('current_event.is_closed');
        },
        canUpdate() {
            return !this.object?.is_locked && Auth.can('update budget entries');
        },
        eventCurrency() {
            return this.store.get('current_event.currency_code');
        },
        isCategory() {
            if (!this.object) {
                return false;
            }
            // Revenue streams have an event and department ID but departments and categories will not
            if (this.object.event_id) {
                return false;
            }
            return !!this.object.department_id;
        },
        availableCategories() {
            return Object.values(this.store.get('current_event.categories')).filter((category) => {
                return (
                    (category.department_id == this.object.id || category.department_id == this.object.department_id) &&
                    !category.is_pooled &&
                    !category.is_locked
                );
            });
        },
        isValid() {
            return (
                this.newEntryData.name &&
                this.newEntryData.category_id &&
                this.newEntryData.supplier_id &&
                !isNaN(this.newEntryData.fixed_cost) &&
                !isNaN(this.newEntryData.budgeted_cost)
            );
        },
        userCannotApproveSelected() {
            let cannotApprove = this.selectedRows.filter((row) => {
                return !row.current_user_can_approve || row.is_locked;
            });
            return !!cannotApprove.length;
        },
        defaultSupplier() {
            return Object.values(this.store.get('account.suppliers')).find((supplier) => {
                return supplier.is_system && supplier.name === 'TBC';
            });
        },
    },
    watch: {
        isCreating(newVal) {
            if (newVal) {
                setTimeout(() => {
                    this.$refs.titleTextbox.focus();
                }, 50);
            }
        },
    },
    mounted() {
        if (this.isCategory) {
            this.newEntryData.category_id = this.object.id;
        }
        Eventbus.$on('reload:entries', this.reloadData);
    },
    methods: {
        toLocal,
        onFilterUpdate(value, status) {
            if (value) {
                this.filters['budget_entries.status'].push(status);
            } else {
                this.filters['budget_entries.status'] = this.filters['budget_entries.status'].filter(
                    (s) => s !== status
                );
            }
        },
        openCategory(category) {
            setRoute('account.event.budget', {
                page: category.is_revenue ? 'revenue' : 'costs',
                departmentId: category.department_id,
                categoryId: category.id,
            });
        },
        openSupplier(supplier) {
            if (Auth.can('manage suppliers')) {
                window.open(
                    route('account.contacts', {
                        supplier: supplier.id,
                    }),
                    '_blank'
                );
                return;
            }
            Eventbus.$emit('view:supplier', supplier);
        },
        openEntry(entry) {
            Eventbus.$emit('budget:entry', {
                entry: entry.id,
                scenarioId: this.store.get('current_scenario.id'),
            });
        },
        getEntryType(budgetEntry) {
            const category = budgetEntry.category;
            // the objectData is a category, not a department, uses is_revenue
            const categoryType = Object.values(this.store.get('current_event.categories')).filter(
                (cat) => cat.id === category.id
            )[0];
            if (categoryType.is_revenue === true) {
                return 'revenue';
            }
            return 'cost';
        },
        rechargeEntry(entry) {
            Eventbus.$emit('rechargeDrawer:open', {
                budgetEntry: entry,
                type: this.type,
            });
        },
        createEntry() {
            if (!this.isValid) {
                return;
            }
            this.isLoading = true;
            axios
                .post(route('api.account.event.entries.create'), this.newEntryData)
                .then(() => {
                    this.reloadData();
                })
                .finally(() => {
                    this.isLoading = false;
                    this.isCreating = false;
                    this.newEntryData = {
                        name: '',
                        category_id: this.isCategory ? this.object.id : null,
                        supplier_id: this.defaultSupplier?.id,
                        user_id: this.store.get('current_user.id'),
                        fixed_cost: 0,
                        budgeted_cost: 0,
                    };
                });
        },
        updateBudgeted(entry, markup = false, additional = {}) {
            axios
                .post(route('api.account.event.entries.update', { entry: entry.id }), {
                    budgeted_cost: entry.budgeted_cost,
                    ...additional,
                })
                .then(() => {
                    if (!markup) return;
                    toast('Markup Entry Updated', 'The marked up entry has been updated successfully', 'success');
                })
                .finally(() => {
                    this.isSavingMarkup = false;
                });
        },
        reloadData(resetSelection = false) {
            if (resetSelection) {
                this.selectedRows = [];
            }
            this.$refs.table?.refreshData();
        },
        createSupplier(supplierName) {
            this.$refs.createSupplierModal.open({
                supplierName: supplierName,
            });
        },
        supplierCreated(supplier) {
            this.availableSuppliers.push(supplier);
            this.store.set('account.suppliers.' + this.selectedSupplier, supplier);
            this.selectedSupplier = supplier.id;
            this.$refs.supplierSelector.setSelection(null, supplier);
        },
        setSupplierSelection(e) {
            e.preventDefault();
            this.$refs.supplierSelector.setFocus();
        },
        setFixedSelection(e) {
            e.preventDefault();
            this.$refs.fixedInput.focus();
        },
        adjustedAmount(entry) {
            const currentScenarioId = this.store.get('current_scenario.id');
            if (!currentScenarioId || entry.adjustments.length === 0) {
                return entry.budgeted_cost;
            }

            const totalAdjustment = entry.adjustments.reduce((acc, curr) => {
                if (entry.id === curr.adjustmentable_id && curr.scenario_id === currentScenarioId) {
                    return acc + curr.value;
                }
                return acc;
            }, 0);

            return parseFloat(entry.budgeted_cost) + parseFloat(totalAdjustment);
        },
        saveMarkup(row) {
            if (!row.markup || isNaN(row.new_markup_val)) {
                return;
            }
            this.isSavingMarkup = true;
            row.markup.budgeted_cost = parseFloat(row.budgeted_cost) + parseFloat(row.new_markup_val);
            // Work out the percentage difference between the new markup and the original budgeted cost and
            // also update fixed cost to reflect the new markup
            const percentage = row.markup.budgeted_cost / (row.budgeted_cost ? row.budgeted_cost : 100);
            this.updateBudgeted(row.markup, true, {
                fixed_cost: row.fixed_cost * (row.markup.markup_sync_fields ? percentage : 1),
            });
            row.is_editing_markup = false;
        },
        createMarkup(entry) {
            this.$refs.budgetmarkupmodal.open({
                donorEntry: entry,
            });
        },
    },
};
</script>
