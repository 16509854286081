<template>
    <div class="ew-data-table tw-bg-white">
        <div
            v-if="(filters || searchable || $slots['actions']) && sidebar"
            class="table-filters-container"
            :class="{
                'is-expanded': filtersExpanded,
            }"
        >
            <div class="table-actions tw-flex tw-flex-col tw-gap-1">
                <div class="tw-relative">
                    <Tooltip position="right" label="Search">
                        <Button
                            v-if="searchable"
                            :active="searchExpanded"
                            @click="
                                async () => {
                                    searchExpanded = !searchExpanded;
                                    await $nextTick();
                                    $refs.tableSearch?.focus();
                                }
                            "
                        >
                            <i v-if="searchExpanded" class="mdi mdi-close"></i>
                            <i v-else class="mdi mdi-magnify"></i>
                        </Button>
                    </Tooltip>
                    <Textbox
                        v-if="searchExpanded"
                        v-model="searchQuery"
                        ref="tableSearch"
                        class="tw-absolute tw-w-[196px] tw-z-10"
                        placeholder="Search..."
                    />
                </div>
                <Tooltip position="right" label="Filters">
                    <Button v-if="filters" :active="filtersExpanded" @click="filtersExpanded = !filtersExpanded">
                        <i v-if="filtersExpanded" class="mdi mdi-chevron-left"></i>
                        <i v-else class="mdi mdi-filter-multiple-outline"></i>
                    </Button>
                </Tooltip>
                <Tooltip v-if="endpoint" position="right" label="Refresh">
                    <Button :active="isLoading" @click="refreshData()">
                        <div
                            :class="{
                                'tw-animate-spin': isLoading,
                            }"
                        >
                            <i class="mdi mdi-reload"></i>
                        </div>
                    </Button>
                </Tooltip>
                <slot name="actions" />
            </div>
            <div v-if="filtersExpanded" class="table-filters">
                <h5 class="mt-3">Filter Results</h5>
                <slot name="filters" :filters="filters" />
            </div>
        </div>
        <div class="table tw-overflow-x-auto !tw-mb-0">
            <slot name="before" />
            <table
                class="table !tw-mb-0"
                :class="{
                    'is-centered': centered,
                }"
            >
                <thead v-if="header">
                    <tr>
                        <th v-if="checkable && tableData.length" width="38">
                            <Checkbox
                                :key="checkedRows.length"
                                :model-value="!tableData.find((r) => !checkedRows.find((c) => c.id === r.id))"
                                @click.native.prevent="
                                    () => {
                                        if (!!tableData.find((r) => !checkedRows.find((c) => c.id === r.id))) {
                                            tableData.forEach((r) => {
                                                if (!checkedRows.find((c) => c.id === r.id)) {
                                                    checkedRows.push(r);
                                                }
                                            });
                                        } else {
                                            checkedRows = checkedRows.filter(
                                                (c) => !tableData.find((r) => r.id === c.id)
                                            );
                                        }
                                    }
                                "
                            />
                        </th>
                        <slot name="col-prepend-header" />
                        <th
                            v-for="column in visibleColumns"
                            :key="column.field"
                            :class="{
                                'is-sortable': column.sortable,
                            }"
                            class="tw-content-center"
                            :width="column.width ? column.width : null"
                            @click="
                                column.sortable
                                    ? toggleSort(
                                          column.field,
                                          sorts[column.field] === 'asc'
                                              ? 'desc'
                                              : sorts[column.field] === 'desc'
                                                ? null
                                                : 'asc'
                                      )
                                    : null
                            "
                        >
                            <slot
                                v-if="$slots[`heading(${column.field})`]"
                                :column="column"
                                :name="`heading(${column.field})`"
                            />
                            <span v-else>{{ column.title }}</span>
                            <i v-if="sorts[column.field] === 'asc'" class="mdi mdi-arrow-up"></i>
                            <i v-else-if="sorts[column.field] === 'desc'" class="mdi mdi-arrow-down"></i>
                        </th>
                        <slot name="col-append-header" />
                    </tr>
                </thead>
                <tbody class="tw-text-black">
                    <slot name="prepend" />
                    <template v-for="(row, rowIndex) in tableData" :key="rowIndex">
                        <tr
                            v-if="!isLoading"
                            :class="[
                                typeof rowClass === 'function' ? rowClass(row) : rowClass,
                                $slots['expanded-row'] ? 'tw-cursor-pointer' : '',
                            ]"
                            @click="onRowClick(row, rowIndex)"
                        >
                            <td v-if="checkable">
                                <Checkbox
                                    v-if="!rowCheckable || rowCheckable(row)"
                                    :id="`check-row-${rowIndex}`"
                                    :model-value="!!checkedRows.find((r) => r.id === row.id)"
                                    @click.native.stop="
                                        () => {
                                            if (!checkedRows.find((r) => r.id === row.id)) {
                                                checkedRows.push(row);
                                            } else {
                                                checkedRows = checkedRows.filter((r) => r.id !== row.id);
                                            }
                                        }
                                    "
                                />
                            </td>
                            <slot name="col-prepend" :row="row" :index="rowIndex" />
                            <td
                                v-for="column of visibleColumns"
                                :key="column.field"
                                class="tw-content-center"
                                :class="column.class"
                            >
                                <slot :name="`column(${column.field})`" :row="row" :index="rowIndex">
                                    {{ row[column.field] }}
                                </slot>
                            </td>
                            <slot name="col-append" :row="row" :index="rowIndex" />
                        </tr>
                        <tr v-if="expandedRows.includes(rowIndex)">
                            <td class="!tw-p-0" :colspan="visibleColumns.length + (checkable ? 1 : 0)">
                                <slot :name="`expanded-row`" :row="row" />
                            </td>
                        </tr>
                        <slot name="row-append" :row="row" />
                    </template>
                    <tr v-if="isLoading">
                        <td :colspan="visibleColumns.length"><slot name="loading">Loading...</slot></td>
                    </tr>
                    <tr v-else-if="!isLoading && tableData?.length === 0">
                        <td :colspan="visibleColumns.length" class="tw-text-center">
                            <slot name="empty">No data available</slot>
                        </td>
                    </tr>
                    <slot name="append" />
                </tbody>
                <slot name="footer" />
            </table>
            <div v-if="endpoint" class="tw-grid tw-grid-cols-2 tw-gap-2 tw-border-t-2 tw-border-gray-300">
                <div v-if="totalItems > 0" class="tw-p-3 tw-flex tw-items-center tw-gap-1.5">
                    <Select v-model="perPage">
                        <option v-for="option in perPageOptions" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </Select>
                    items per page
                    <span class="tw-text-xs tw-text-gray-400">
                        [{{ totalItems }} {{ totalItems == 1 ? 'item' : 'items' }} in total]
                    </span>
                </div>
                <div v-if="totalItems > perPage" class="tw-p-3 tw-col-start-2 tw-flex tw-justify-end">
                    <Paginator
                        :data="{
                            data: tableData,
                            per_page: perPage,
                            current_page: currentPage,
                            total: totalItems,
                        }"
                        @change-page="refreshData"
                    />
                </div>
            </div>
            <slot name="after" />
        </div>
    </div>
</template>

<script>
import Textbox from '../controls/Textbox.vue';
import Button from '../controls/Button.vue';
import Checkbox from '../controls/Checkbox.vue';
import Select from '../controls/Select.vue';
import Paginator from '../controls/Paginator.vue';
import debounce from 'lodash/debounce';
import Tooltip from '@/js/components/Tooltip.vue';

export default {
    components: {
        Textbox,
        Button,
        Checkbox,
        Select,
        Paginator,
        Tooltip,
    },
    provide() {
        return {
            $table: this,
        };
    },
    props: {
        endpoint: {
            type: String,
            required: false,
        },
        data: {
            type: [Array, Object],
            required: false,
        },
        rowKey: {
            type: String,
            default: 'id',
        },
        rowClass: {
            type: [Function, String],
            required: false,
        },
        rowCheckable: {
            type: Function,
            required: false,
        },
        centered: {
            type: Boolean,
            default: false,
        },
        perPageOptions: {
            type: Array,
            default: () => [10, 25, 40, 50],
        },
        filters: {
            type: Object,
            required: false,
        },
        searchable: {
            type: Boolean,
            default: false,
        },
        searchableString: {
            type: String,
            default: null,
        },
        checkable: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: Array,
            default: null,
        },
        header: {
            type: Boolean,
            default: true,
        },
        sidebar: {
            type: Boolean,
            default: true,
        },
        columns: {
            type: Array,
            required: true,
        },
    },
    emits: ['update:modelValue', 'options', 'select'],
    data() {
        return {
            isLoading: true,
            tableData: this.data ? (Array.isArray(this.data) ? this.data : Object.values(this.data)) : [],
            currentPage: 1,
            perPage: this.perPageOptions[0] ?? 10,
            totalItems: 0,
            sorts: {},
            filtersExpanded: false,
            searchQuery: null,
            searchExpanded: false,
            checkedRows: this.modelValue ?? [],
            expandedRows: [],
        };
    },
    computed: {
        visibleColumns() {
            return this.columns.filter((column) => !column.hidden);
        },
        hasResults() {
            return this.tableData.length > 0;
        },
    },
    watch: {
        data: {
            handler(newVal) {
                this.tableData = Array.isArray(newVal) ? newVal : Object.values(newVal);
            },
            deep: true,
        },
        perPage() {
            this.refreshData(1);
        },
        filters: {
            handler() {
                this.refreshData();
            },
            deep: true,
        },
        searchableString(newVal) {
            this.searchQuery = newVal;
        },
        searchQuery() {
            this.debouncedRefreshData();
        },
        searchExpanded(newVal) {
            if (!newVal) {
                this.searchQuery = null;
            }
        },
        modelValue(newVal) {
            this.checkedRows = newVal;
        },
        checkedRows: {
            handler(newVal) {
                this.$emit('update:modelValue', newVal);
            },
            deep: true,
        },
        endpoint() {
            this.refreshData();
        },
        sorts: {
            handler() {
                this.sortData();
            },
            deep: true,
        },
    },
    mounted() {
        if (!this.tableData && !this.endpoint) {
            return;
        }
        if (this.endpoint) {
            this.refreshData();
            return;
        }
        this.isLoading = false;
    },
    methods: {
        onRowClick(row, index) {
            if (this.$slots['expanded-row']) {
                this.expandedRows.includes(index)
                    ? this.expandedRows.splice(this.expandedRows.indexOf(index), 1)
                    : this.expandedRows.push(index);
            }
            this.$emit('select', row);
        },
        refreshData(newPage = null) {
            if (!this.endpoint) {
                return;
            }
            if (!newPage) {
                newPage = this.currentPage;
            }
            this.isLoading = true;
            const url = new URL(this.endpoint);
            url.searchParams.append('paginated', 1);
            url.searchParams.append('perPage', this.perPage);
            url.searchParams.append('page', newPage ?? this.currentPage);
            if ((this.searchable || this.searchableString) && this.searchQuery) {
                url.searchParams.append('query', this.searchQuery);
            }
            if (Object.keys(this.sorts).length) {
                url.searchParams.append(
                    'sort',
                    Object.keys(this.sorts)
                        .map((key) => `${key}=${this.sorts[key]}`)
                        .join(',')
                );
            }
            if (this.filters && Object.keys(this.filters).length) {
                const filterString = Object.keys(this.filters)
                    .map((key) => {
                        if (Array.isArray(this.filters[key])) {
                            return `${key}=${this.filters[key].join(',')}`;
                        }
                        if (!this.filters[key]) {
                            return null;
                        }
                        return `${key}=${this.filters[key]}`;
                    })
                    .filter((f) => f)
                    .join(';');
                if (filterString) {
                    url.searchParams.append('filter', filterString);
                }
            }
            axios
                .get(url.toString())
                .then(({ data }) => {
                    if (data.options) {
                        this.$emit('options', data.options);
                    }
                    this.tableData = data.data;
                    this.currentPage = data.current_page;
                    this.totalItems = data.total;
                })
                .finally(() => (this.isLoading = false));
        },
        debouncedRefreshData: debounce(function () {
            this.currentPage = 1;
            this.refreshData();
        }, 300),
        toggleSort(field, order = null) {
            this.sorts = {};
            if (order) {
                this.sorts[field] = order;
            } else {
                delete this.sorts[field];
            }
            this.refreshData();
        },
        sortData() {
            // We don't want to do manual sorting if data is server side paginated
            if (this.endpoint) return;
            // sort the tabledata asc/desc by column/field

            // Pick out the column name and index to sort on
            const sortingColumn = Object.keys(this.sorts)[0];
            const sortDirection = Object.values(this.sorts)[0];
            if (sortDirection === 'desc') {
                this.tableData.sort((item1, item2) =>
                    item1[sortingColumn] < item2[sortingColumn]
                        ? 1
                        : item1[sortingColumn] > item2[sortingColumn]
                          ? -1
                          : 0
                );
            } else if (sortDirection === 'asc') {
                this.tableData.sort((item1, item2) =>
                    item1[sortingColumn] > item2[sortingColumn]
                        ? 1
                        : item1[sortingColumn] < item2[sortingColumn]
                          ? -1
                          : 0
                );
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/eventbooks/_variables.scss';

.ew-data-table {
    display: flex;

    .table-filters-container {
        display: flex;
        width: 52px;
        border-right: 1px solid rgba(black, 0.1);

        .table-filters {
            flex: 1;
            padding-left: 5px;
        }
        .table-actions {
            padding: 6px;
        }

        &.is-expanded {
            width: 300px;
        }
    }

    .table {
        th,
        td {
            padding: 0.5rem 0.75rem;
        }

        th {
            user-select: none;
            color: black;
            font-weight: 500;

            &.is-sortable {
                @apply tw-text-eventwise-link;
                opacity: 0.9;
                cursor: pointer;

                &:hover {
                    opacity: 1;
                    background: rgba(black, 0.025);
                }
            }
        }
        &.is-centered {
            td {
                vertical-align: middle;
            }
        }
    }
}
</style>
