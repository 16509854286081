<template>
    <Drawer
        ref="drawer"
        title="New Purchase Order"
        :z-index="5005"
        :loading="!isLoaded"
        size="is-large"
        @closed="resetForm()"
    >
        <div class="tw-mt-3 tw-px-4 tw-pb-3">
            <FormField label="Title" class="tw-mb-2">
                <Textbox v-model="formData.title" required />
            </FormField>
            <div class="tw-grid tw-grid-cols-2 tw-gap-3 border-bottom">
                <FormField label="Department">
                    <Selector v-model="departmentId" :options="availableDepartments" />
                </FormField>
                <FormField label="Subcategory">
                    <Selector v-model="categoryId" :options="availableCategories" />
                </FormField>
                <template v-if="categoryId">
                    <FormField label="Supplier">
                        <SupplierSelector v-model="formData.supplier_id" />
                    </FormField>
                    <FormField label="Budget Entry (optional)">
                        <modal-selector
                            v-model="formData['budget_entry_id']"
                            :endpoint="
                                route('api.account.event.entries', {
                                    category_id: categoryId,
                                })
                            "
                            modal-title="Link Budget Entry"
                            button-title="Link a Budget Entry"
                            allow-search
                        />
                    </FormField>
                    <FormField label="Default Tax Rate">
                        <Banner v-if="!taxRates.length" type="is-warning">
                            You don't have any tax rates set up for expenses just yet. Head over to
                            <a
                                :href="
                                    route('account.settings', {
                                        page: 'tax-rates',
                                    })
                                "
                            >
                                account settings
                            </a>
                            to set one up.
                        </Banner>
                        <TaxRateSelector v-else v-model="formData.tax_rate_id" type="expense" />
                    </FormField>
                    <FormField label="Target Currency">
                        <Select v-model="formData['target_currency']" @update:model-value="getDefaultConversionRate()">
                            <option
                                v-for="(currency, currencyCode) in availableCurrencies"
                                :key="currencyCode"
                                :value="currencyCode"
                            >
                                {{ currency }}
                            </option>
                        </Select>
                    </FormField>
                    <FormField
                        v-if="formData['target_currency'] != store.get('current_event.currency_code')"
                        field-class="tw-flex"
                        label="Conversion Rate"
                        field-for="conversion-rate"
                    >
                        <NumberInput
                            v-model="formData['conversion_rate']"
                            :currency="formData['target_currency']"
                            name="conversion_rate"
                            label="Conversion Rate"
                        />
                        <Button class="!tw-ml-1" @click="openCurrencyConverter()">
                            <i class="mdi mdi-calculator"></i>
                        </Button>
                    </FormField>

                    <FormField v-if="categoryId && formData.tax_rate_id" class="tw-col-span-2" label="Line Items">
                        <line-item-builder
                            v-model="formData.line_items"
                            :default-tax-rate-id="formData.tax_rate_id"
                            tax-rate-type="expense"
                        />
                    </FormField>
                </template>
            </div>
        </div>
        <template #footer>
            <Button
                type="is-primary"
                class="tw-block tw-ml-auto"
                :loading="!isLoaded"
                :disabled="!isValid"
                @click="submitEntry()"
            >
                Create Purchase Order
            </Button>
        </template>
    </Drawer>
</template>
<script>
import Drawer from '../../../../../js/components/Drawer.vue';
import Selector from '../../../../../js/components/controls/Selector.vue';
import SupplierSelector from '../../../../../js/components/controls/SupplierSelector.vue';
import TaxRateSelector from '../../../../../js/components/controls/TaxRateSelector.vue';
import LineItemBuilder from '../../../../../js/components/widgets/LineItemBuilder.vue';
import Button from '@/js/components/controls/Button.vue';
import { useDataStore } from '@/js/stores/DataStore.js';
import FormField from '@/js/components/widgets/FormField.vue';
import Textbox from '@/js/components/controls/Textbox.vue';
import Banner from '@/js/components/Layout/Banner.vue';
import Select from '@/js/components/controls/Select.vue';
import NumberInput from '@/js/components/controls/NumberInput.vue';
import { toast } from '@/js/utils.js';

export default {
    components: {
        Drawer,
        LineItemBuilder,
        Selector,
        SupplierSelector,
        TaxRateSelector,
        Button,
        FormField,
        Textbox,
        Banner,
        Select,
        NumberInput,
    },
    data() {
        const store = useDataStore();
        return {
            isOpen: false,
            isLoaded: false,
            availableCurrencies: store.get('available_currencies'),
            availableDepartments: Object.values(store.get('current_event.cost_departments')),
            departmentId: null,
            categoryId: null,
            availableCategories: [],
            availableSuppliers: Object.values(store.get('account.suppliers')),
            taxRates: Object.values(store.get('account.tax_rates')).filter((t) => t.type === 'expense'),
            formData: {
                line_items: [
                    {
                        description: '',
                        unit_price: 0,
                        quantity: 0,
                    },
                ],
                supplier_id: null,
                budget_entry_id: null,
                tax_rate_id: null,
                target_currency: store.get('current_event.currency_code'),
                conversion_rate: 1,
                title: '',
            },
            store: store,
        };
    },
    computed: {
        isValid() {
            return (
                !!this.categoryId &&
                !!this.formData.title &&
                !!this.formData.tax_rate_id &&
                !!this.formData.supplier_id &&
                !!this.formData.line_items.filter(
                    (lineItem) => lineItem.description && lineItem.quantity && lineItem.unit_price
                ).length
            );
        },
    },
    watch: {
        formData: {
            handler(newVal) {
                if (typeof newVal.tax_rate_id === 'boolean') {
                    this.formData.tax_rate_id = null;
                } else if (typeof newVal.tax_rate_id === 'string') {
                    this.formData.tax_rate_id = parseInt(newVal.tax_rate_id);
                }
                this.fillDefaultTaxRates(this.formData.tax_rate_id);
            },
            deep: true,
        },
        departmentId(newVal, oldVal) {
            if (oldVal) {
                this.categoryId = null;
            }
            this.reloadCategories();
            this.formData.tax_rate_id =
                this.store.get(`account.departments.${newVal}.default_tax_rate_id`) ||
                this.store.get('account.default_purchase_tax_rate_id') ||
                null;
            if (this.formData.tax_rate_id) {
                this.fillDefaultTaxRates(this.formData.tax_rate_id);
            }
        },
    },
    mounted() {
        Eventbus.$on('purchase-order:create', (payload) => {
            this.isOpen = true;
            this.resetForm();
            if (payload) {
                if (payload.department) {
                    this.departmentId = payload.department;
                    this.categoryId = payload.category ?? null;
                }
                this.formData.title = payload.title ?? '';
                this.formData.supplier_id = payload.supplier ?? null;
                this.formData.budget_entry_id = payload.entry ?? null;
            }
            this.$refs.drawer.open();
            this.isLoaded = true;
        });
    },
    methods: {
        closeModal() {
            this.isOpen = false;
            this.isLoaded = false;
            this.resetForm();
            this.$refs.drawer.close();
        },
        resetForm() {
            this.departmentId = null;
            this.categoryId = null;
            this.formData = {
                line_items: [
                    {
                        description: '',
                        unit_price: 0,
                        quantity: 0,
                    },
                ],
                supplier_id: null,
                budget_entry_id: null,
                tax_rate_id: null,
                target_currency: this.store.get('current_event.currency_code'),
                conversion_rate: 1,
                title: '',
            };
        },
        submitEntry() {
            if (!this.isValid) {
                toast('Error', 'Please check you have entered all required fields and try to submit again', 'error');
                return;
            }

            this.isLoaded = false;
            this.formData.category_id = this.categoryId;
            axios
                .post(route('api.account.event.purchaseorders.create'), this.formData)
                .then((response) => {
                    Eventbus.$emit('reload:purchaseorders', {});
                    Eventbus.$emit('open:PurchaseOrder', response.data.id);
                    toast('Success', 'Purchase Order Created', 'success');
                    this.closeModal();
                })
                .finally(() => (this.isLoaded = true));
        },
        reloadCategories() {
            this.availableCategories = Object.values(this.store.get('current_event.categories')).filter(
                (category) => category.department_id === this.departmentId && !category.is_pooled
            );
        },
        fillDefaultTaxRates(newVal) {
            this.formData.line_items.forEach((item, index) => {
                if (!item.tax_rate_id) {
                    this.formData.line_items[index].tax_rate_id = newVal;
                }
            });
        },
        openCurrencyConverter() {
            Eventbus.$emit('currencyConverter');
        },
        getDefaultConversionRate() {
            axios
                .get(
                    route('api.currency.convert', {
                        inputtext: '1',
                        convert_from: this.store.get('current_event.currency_code'),
                        convert_to: this.formData.target_currency,
                    })
                )
                .then(({ data }) => (this.formData.conversion_rate = data.rate));
        },
    },
};
</script>
